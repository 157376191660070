<template>
  <div class="box">
    <h2>一物一码活动儿童个人信息保护规则及监护人须知</h2>
    <p>
      一物一码活动主办方（或简称“我们”）深知保护儿童（指未满十四周岁的未成年人，下同）个人信息安全和隐私的重要性。在《隐私政策》的基础上，我们希望通过《一物一码活动儿童个人信息保护规则及监护人须知》（以下简称“本政策”）说明我们在收集和使用儿童个人信息时对应的处理规则等相关事宜。您作为您孩子的父母或其他监护人（以下统称为“监护人”），<u
        >请在您或您的孩子参与本次活动前，务必仔细阅读并透彻理解本政策，特别是以粗体/粗体下划线标识的条款应重点阅读，在确认充分理解并同意全部条款后再开始使用或允许您的孩子使用。</u
      >
    </p>
    <u>
      <p>
        您的理解和配合对我们保护您孩子的个人信息和隐私安全非常必要，为了维护您孩子的合法权益，我们希望您可以协助我们，确保您的孩子在您的同意和指导下使用我们的产品及服务和向我们提交个人信息。我们将根据本政策采取特殊措施保护我们获得的您孩子的个人信息。如果您不同意本政策的内容，请您要求您的孩子立即停止访问/使用我们的产品及服务。
      </p>
      <p>儿童特别说明：</p>
      <p></p>
      <p>
        任何儿童参加网上活动都应事先取得监护人的同意。如果您是儿童，请务必通知您的监护人共同阅读本政策，并在您使用我们的产品及服务、提交个人信息之前，寻求您的监护人的同意和指导。
      </p></u
    >
    <p>
      本政策适用于我们在中华人民共和国境内通过网络从事收集、存储、使用、转移、分享和披露儿童个人信息等活动。本政策为在《隐私政策》基础上制定的特别规则，与《隐私政策》如有不一致之处，以本政策为准；本政策未载明之处，则参照适用《隐私政策》。
    </p>
    <p>
      在您阅读完本政策后，如对本政策或与本政策相关的事宜有任何问题，可通过<u>本政策“七、如何联系我们”</u>章节所列的反馈渠道联系我们，我们会尽快为您作出解答。
    </p>
    <p>
      <b>本政策将帮助您了解以下内容：</b>
    </p>
    <p>一、我们如何收集和使用儿童个人信息</p>
    <p>二、我们如何共享、转让、公开披露儿童个人信息</p>
    <p>三、我们如何储存儿童个人信息</p>
    <p>四、我们如何保护儿童个人信息</p>
    <p>五、如何管理儿童个人信息</p>
    <p>六、本政策的修订</p>
    <p>七、如何联系我们</p>
    <p>
      <b>一、我们如何收集和使用儿童个人信息</b>
    </p>
    <p>
      （一）我们会严格履行法律法规规定的儿童个人信息保护义务与责任，遵循正当必要、知情同意、目的明确、安全保障、依法利用的原则，在征得监护人的同意后收集和使用儿童个人信息。
    </p>
    <p>
      （二）您或您的孩子在参与本次活动中，我们为向您或者您的孩子提供我们的活动支持（如实物奖领取）目的，我们可能还会收集和使用其他儿童个人信息，有关具体的使用目的、收集个人信息的范围以及拒绝提供相应信息的后果请您查阅《隐私政策》
    </p>
    <p>“数据的收集，数据的使用”章节进行详细了解。</p>
    <p>
      此外，我们也会通过Cookies、SDK等同类技术自动收集您或您孩子的个人信息，具体请您查阅《隐私政策》
    </p>
    <p>
      “我们如何使用Cookies和同类技术”部分进行详细了解。如我们需要超出上述范围收集和使用儿童个人信息，我们将再次征得监护人的同意。
    </p>
    <p>
      <u
        >请您注意，绝大多数情形下我们无法识别且不会判断收集和处理的个人信息是否属于儿童个人信息，我们将按照《隐私政策》收集和处理用户的个人信息。</u
      >
    </p>
    <p>（三）征得授权同意的例外</p>
    <p>
      根据相关法律法规规定，以下情形中收集儿童的信息无需征得儿童监护人的授权同意：
    </p>
    <p>1、与我们履行法律法规规定的义务相关的；</p>
    <p>2、与国家安全、国防安全、公共安全、公共卫生、重大公共利益有关的；</p>
    <p>3、与犯罪侦查、起诉、审判和判决执行等有关的；</p>
    <p>4、出于维护儿童的生命、财产等重大合法权益但又很难得到监护人同意的；</p>
    <p>5、所收集的信息是儿童或监护人自行向社会公众公开的；</p>
    <p>
      6、从合法公开披露的信息中收集信息的，如合法的新闻报道、政府信息公开等渠道；
    </p>
    <p>7、根据与您或您的孩子签订和履行相关协议或其他书面文件所必需的；</p>
    <p>
      8、用于维护我们的产品及服务的安全稳定运行所必需的，例如发现、处置我们的产品及服务的故障；
    </p>
    <p>
      9、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的信息进行去标识化处理的；
    </p>
    <p>10、法律法规规定的其他情形。</p>
    <p><b>二、我们如何共享、转让、公开披露儿童个人信息</b></p>
    <p>
      我们承诺对儿童个人信息进行严格保密，仅在符合本政策说明和您明确授权同意的情况下对外共享儿童个人信息。<u
        >为了提升信息处理效率，降低信息处理成本，或提高信息处理准确性，我们可能会委托有能力的我们的关联公司或其他专业机构代表我们来处理儿童个人信息，具体请参见《隐私政策》
      </u>
    </p>
    <p>“数据的共享、转让、公开披露”部分。</p>
    <p>
      <u>
        除了《隐私政策》“数据的共享、转让、公开披露”部分所述的内容外，对于儿童个人信息，我们还将会采取如下措施来保护儿童个人信息在共享、转让和公开披露过程中的安全：
      </u>
    </p>
    <p>
      1、如果为了本政策所述目的而需要将儿童个人信息共享至第三方，我们将评估该第三方收集儿童个人信息的合法性、正当性、必要性。
    </p>
    <p>
      2、我们将要求第三方对儿童个人信息采取保护措施，并且严格遵守相关法律法规与监管要求。我们会要求接收儿童个人信息的第三方遵守严格的保密义务及采取有效的保密措施，禁止其将这些儿童个人信息用于未经儿童及其监护人授权的用途，并要求受托公司依法履行以下义务：
    </p>
    <p>（1）按照法律、行政法规的规定和我们的要求处理儿童个人信息；</p>
    <p>（2）协助我们回应儿童监护人提出的申请；</p>
    <p>
      （3）采取措施保障信息安全，并在发生儿童个人信息泄露安全事件时，及时向我们反馈；
    </p>
    <p>（4）委托关系解除时及时删除儿童个人信息；</p>
    <p>（5）不得转委托；</p>
    <p>（6）其他依法应当履行的儿童个人信息保护义务。</p>
    <p>
      3、另外，我们会按照法律法规的要求征得您的同意，或确认第三方已经征得您同意。
    </p>
    <p>
      <b>三、我们如何储存儿童个人信息</b>
    </p>
    <p>
      <u
        >（一）我们在中华人民共和国境内运营中收集和产生的儿童个人信息，将存储在中国境内。</u
      >以下情形下，我们会在履行了法律规定的义务后，向境外的组织或个人提供儿童的个人信息：
    </p>
    <p>1、适用的法律有明确规定；</p>
    <p>2、获得您的明确授权；</p>
    <p>3、您或者您的孩子通过互联网进行跨境交易等个人主动行为。</p>
    <p>
      针对以上情形，我们会通过合同等形式确保以不低于本政策规定的程度保护儿童的个人信息。
    </p>
    <p>
      （二）我们会采取合理可行的措施，尽力避免收集和处理无关的儿童的个人信息。<u>我们只会在达成本政策所述目的所需的期限内保留儿童的个人信息，除非法律有强制的留存要求。</u>关于我们如何确定儿童个人信息存储期限请参见《隐私政策》“数据的存储”。如我们终止活动或运营，我们将及时停止继续收集儿童个人信息的活动，同时会遵守相关法律法规要求提前向监护人通知，并在终止服务或运营后对儿童的个人信息进行删除或匿名化处理，但法律法规或监管部门另有规定的除外。
    </p>
    <p>
      <b>四、我们如何保护儿童个人信息</b>
    </p>
    <p>
      我们非常重视儿童的隐私安全，并采取一切合理可行的措施，保护儿童个人信息：
    </p>
    <p>
      <u>（一）我们会严格控制儿童个人信息的访问权限，</u
      >对可能接触到儿童个人信息的工作人员采取最小够用授权原则，并采取技术措施对工作人员处理儿童个人信息的行为进行记录和管控，避免违法复制、下载儿童个人信息。
    </p>
    <p>
      （二）我们已制定<u>儿童个人信息安全事件应急预案</u>，我们会定期组织内部相关人员进行应急响应培训和应急演练，使其掌握岗位职责和应急处置策略和规程。在不幸发生儿童个人信息安全事件后，我们将按照法律法规的要求，及时向儿童及其监护人告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、儿童及其监护人可自主防范和降低风险的建议、对儿童及其监护人的补救措施等。我们将及时将事件相关情况以发送邮件/短消息等方式告知儿童及其监护人。难以逐一告知时，我们会采取合理、有效的方式发布相关警示信息。同时，我们还将按照监管部门要求，主动上报儿童个人信息安全事件的处置情况。若儿童及其监护人的合法权益受损，我们将承担相应的法律责任。
    </p>
    <p>
      （三）如您希望了解更多，请查阅《隐私政策》<u>“数据安全与个人信息保护的合规管理体系”</u>章节，详细了解我们如何采取了哪些措施保护儿童个人信息。
    </p>
    <p>
      <b>五、如何管理儿童个人信息</b>
    </p>
    <p>
      您可以通过客服联系我们的方式查询和管理您的信息，下列情形下，您或您的孩子可以通过《隐私政策》“联系我们”所列的渠道联系我们，要求删除相应的儿童个人信息：
    </p>
    <p>
      1、我们违反法律、行政法规的规定或者双方的约定收集、存储、使用、对外提供儿童个人信息的；
    </p>
    <p>2、超出目的范围或者必要期限收集、存储、使用、对外提供儿童个人信息的；</p>
    <p>3、您撤回同意的；</p>
    <p>4、您或您的孩子通过注销等方式终止使用产品或者服务的。</p>
    <p>
      <b>六、本政策的修订</b>
    </p>
    <p>
      我们可能会根据我们的产品及服务的更新情况及法律法规的相关要求适时修改本政策的条款，该等修改构成本政策的一部分，我们会在专门页面上展示最新修改版本。
    </p>
    <p>
      <b> 七、如何联系我们</b>
    </p>
    <p>
      如您和您的孩子对本政策存在任何疑问，或对于儿童个人信息处理存在任何投诉、意见，请通过以下渠道联系我们，我们将在收到问题、意见或建议，并验证监护人和/或儿童身份后的十五天内回复您的请求：
    </p>
    <p>1、您可通过活动页面“问题咨询”在线客服与我们联系；</p>
    <p>
      2、如发现个人信息可能被泄露，您可以通过<a href="tel:4008159210"
        >400-815-9210</a
      >投诉举报；
    </p>
    <p>3、您可以通过发送邮件至jubao@shangjietech.com与我们联系。</p>
    <p>需注意的是，我们可能不会回复与本政策或与个人信息保护无关的问题。</p>
  </div>
</template>

<script>
export default {
  name: 'PrivacyChildren',
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
.box {
  padding: 20px;
  font-size: 26px;
  line-height: 46px;
  color: #333;
  h2 {
    font-size: 30px;
    text-align: center;
    font-weight: bold;
    margin: 20px 0;
  }
  u {
    font-weight: bold;
  }
  a {
    color: #333;
  }
}
</style>
